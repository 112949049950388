/*Home Page*/

.home {
  background: #f6f7fa;
  height: 100vh;
  overflow: hidden;
}

.home #navigation {
  background: var(--main-color);
}

.home #navigation {
  padding: 0;
}

.home .display-table {
  display: table;
  padding: 0;
  height: 100%;
  width: 100%;
}

.home .display-table-row {
  display: table-row;
  height: 100%;
}

.home .display-table-cell {
  display: table-cell;
  float: none;
  height: 100%;
}

.home .v-align {
  vertical-align: top;
}
.home .logo img {
  max-width: 180px;
  padding: 16px 0 17px;
  width: 100%;
}

.home .header-top {
  margin: 0;
  padding-top: 2px;
}

.home .header-top img {
  border-radius: 50%;
  max-width: 60px !important;
  width: 100%;
}

.home .header-rightside .nav > li > a:focus,
.home .header-rightside .nav > li > a:hover {
  background: none;
  text-decoration: none;
}

.home .icon-info {
  position: relative;
}
.home .navi i {
  font-size: 20px;
}
.home .label.label-primary {
  border-radius: 50%;
  font-size: 9px;
  left: 8px;
  position: absolute;
  top: -9px;
}

.home .icon-info .label {
  border: 2px solid #ffffff;
  font-weight: 500;
  padding: 3px 5px;
  text-align: center;
}

.home .header-top li {
  display: inline-block;
  text-align: center;
}

.home .header-top .dropdown-toggle {
  color: var(--main-color);
}

.home .header-top .dropdown-menu {
  border: medium none;
  left: -85px;
  padding: 17px;
}
.home .view {
  background: var(--panel-text-color) none repeat scroll 0 0;
  border-radius: 100px;
  color: #ffffff;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  padding: 10px 15px;
}

.home .navbar-content > span {
  font-size: 13px;
  font-weight: 700;
}

.home .img-responsive {
  width: 100%;
}
.home #navigation{
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.home header {
  background: #ffffff none repeat scroll 0 0;
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.04);
  display: inline-block !important;
  line-height: 23px;
  padding-left: 15px;
  padding-right: 15px;
  transition: all 0.5s ease 0s;
  width: 100%;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.home .logo {
  text-align: center;
}

.navi a {
  border-bottom: 1px solid var(--main-color);
  border-top: 1px solid var(--main-color);
  color: #ffffff;
  display: block;
  font-size: 17px;
  font-weight: 500;
  padding: 28px 20px;
  text-decoration: none;
  cursor: pointer;
}

.navi i {
  margin-right: 15px;
  color: var(--panel-text-color);
}

.navi .active a {
  background: var(--secondary-color);
  border-left: 5px solid var(--panel-text-color);
  padding-left: 15px;
}

.navi a:hover {
  background: var(--secondary-color) none repeat scroll 0 0;
  border-left: 5px solid var(--panel-text-color);
  display: block;
  padding-left: 15px;
}

.home .navbar-default {
  background-color: #ffffff;
  border-color: #ffffff;
}

.home .navbar-toggle {
  border: none;
}

.home .navbar-default .navbar-toggle:focus,
.home .navbar-default .navbar-toggle:hover {
  background-color: rgba(0, 0, 0, 0);
}

.home .navbar-default .navbar-toggle .icon-bar {
  background-color: #0e1a35;
}

.circle-logo {
  margin: 0 auto;
  max-width: 30px !important;
  text-align: center;
}

.home .hidden-xs{
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.user-dashboard {
  padding: 0 20px;
}

.user-dashboard h1 {
  color: #0e1a35;
  font-size: 30px;
  font-weight: 500;
  margin: 0;
  padding: 21px 0;
}
.btn.btn-secondary.btn-lg.dropdown-toggle > span {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

/*Main CSS*/

@media only screen and (max-device-width: 767px) {
  .tab-content .tab-pane {
    padding: 20px 0;
  }
  #navigation .navi a {
    font-size: 14px;
    padding: 20px;
    text-align: center;
  }
  #navigation .navi i {
    margin-right: 0px;
  }
  #navigation .navi a:hover,
  #navigation .navi .active a {
    background: var(--main-color) none repeat scroll 0 0;
    border-left: none;
    display: block;
    padding-left: 20px;
  }
  header .header-top img {
    max-width: 38px !important;
  }
  .v-align header {
    padding: 12px 15px;
  }
  header .header-top li {
    padding-left: 13px;
    padding-right: 6px;
  }
  .home .navbar-default .navbar-toggle {
    border-color: rgba(0, 0, 0, 0);
  }
  .home .navbar-header .navbar-toggle {
    float: left;
    margin: 0;
    padding: 0;
    top: 12px;
  }
  .home button,
  .home html [type="button"],
  .home [type="reset"],
  .home [type="submit"] {
    outline: medium none;
  }
  .user-dashboard .btn.btn-secondary.btn-lg.dropdown-toggle > span {
    font-size: 11px;
  }
  .gutter{
    padding: 0;
  }
}

@media only screen and (max-device-width: 992px) {
  header .header-top li {
    padding-left: 20px !important;
    padding-right: 0;
  }
  header .logo img {
    max-width: 125px !important;
  }

}

@media only screen and (min-device-width: 767px) and (max-device-width: 998px){
  .user-dashboard .header-top {
    padding-top: 5px;
  }
  .user-dashboard .header-rightside {
    display: inline-block;
    float: left;
    width: 100%;
  }
  .user-dashboard .header-rightside .header-top img {
    max-width: 41px !important;
  }
  .user-dashboard .sales button {
    font-size: 10px;
  }
  .user-dashboard .btn.btn-secondary.btn-lg.dropdown-toggle > span {
    font-size: 12px;
  }
  .user-dashboard .sales h2 {
    font-size: 15px;
  }
  .col-users{
    height:65vh !important;
  }
}
@media only screen and (min-device-width:998px) and (max-device-width: 1350px){
  #navigation .logo img {
    max-width: 130px;
    padding: 16px 0 17px;
    width: 100%;
  }
  .col-users{
    height:55vh !important;
  }
  .col-tracks{
    height: 55vh !important;
  }
}

/**
  CSS Inicio
 */
.wrimagecard{
  margin-top: 0;
  margin-bottom: 1.5rem;
  text-align: left;
  position: relative;
  background: #fff;
  box-shadow: 12px 15px 20px 0px rgba(46,61,73,0.15);
  border-radius: 4px;
  transition: all 0.3s ease;
  cursor: default;
}
.wrimagecard .wrimagecard-topimage_header .glyphicon{
  position: relative;
  font-size: 70px;
}
.wrimagecard-topimage_header{
  padding: 20px;
}
a.wrimagecard:hover, .wrimagecard-topimage:hover {
  box-shadow: 2px 4px 8px 0px rgba(46,61,73,0.2);
}
.wrimagecard-topimage a {
  width: 100%;
  height: 100%;
  display: block;
}
.wrimagecard-topimage_title {
  padding: 20px 24px;
  height: 80px;
  padding-bottom: 0.75rem;
  position: relative;
}

.wrimagecard-topimage_title.permission{
  background-color: rgba(15, 133, 106, 0.8);
  color: #ffffff;
}
.wrimagecard-topimage a {
  border-bottom: none;
  text-decoration: none;
  color: #000000;
  transition: color 0.3s ease;
}

.bs-callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
}
.bs-callout h3 {
  margin-top: 0;
  margin-bottom: 5px;
}
.bs-callout p:last-child {
  margin-bottom: 0;
}
.bs-callout code {
  border-radius: 3px;
}
.bs-callout+.bs-callout {
  margin-top: -5px;
}
.bs-callout-primary {
  border-left-color: var(--main-color);
}
.bs-callout-primary h3 {
  color: var(--main-color);
}

/**
  CSS Info usuario
 */
.profile
{
  min-height: 355px;
  display: inline-block;
}
figure.user-avatar{
  width: 100%;
}
.divider
{
  border-top:1px solid rgba(0,0,0,0.1);
  padding-top: 20px;
  margin-top: 20px;
}
.divider button{
  margin-bottom: 10px;
}
.tags {
  margin: 4px 4px;
  background: #0f856a;
  border-radius: 2px;
  color: #f5f5f5;
  font-weight: bold;
  padding: 4px 4px;
  cursor: default;
}
.panel-action-btn{
  color: #ffffff;
  background-color: #626262;
  margin-bottom: 10px;
}
.panel-action-btn:hover, .panel-action-btn:active, .panel-action-btn:focus{
  color: #ffffff;
  background-color: #4e4e4e;
}
.trackings th, .searches th{
  color: #ffffff;
  background-color: #626262;
}
.col-users{
  overflow-y: auto;
  height:70vh;
}
.col-users.empty-col{
  height: auto !important;
}
.row-users{
  overflow: hidden;
}

.table-fixed thead {
  width: 100%;
}
.table-fixed tbody {
  height: 65vh;
  overflow-y: auto;
  width: 100%;
}
.table-fixed thead, .table-fixed tbody, .table-fixed tr, .table-fixed td, .table-fixed th {
  display: block;
}
.table-fixed tbody td, .table-fixed thead > tr> th {
  float: left;
  border-bottom-width: 0;
}

.row-users .panel-primary, .panel-ws{
  border-color: var(--main-color);
}

.row-users .panel-heading, .panel-ws .panel-heading{
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.well .btn-delete-user, .btn-panel-action{
  background-color: var(--secondary-color);
  color: #ffffff;
}

.well .btn-delete-user:hover, .btn-panel-action:hover{
  background-color: #B55426;
  color: #ffffff;
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus{
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.list-group-item.active .list-group-item-text, .list-group-item.active:hover .list-group-item-text, .list-group-item.active:focus .list-group-item-text{
  color: #ffffff;
}
/**
  CSS PreAuth User
 */
.preAuth{
  padding: 20px;
}
.col-preAuth{
  overflow-y: auto;
  height: 78vh;
}

.funkyradio div {
  clear: both;
  overflow: hidden;
}
.funkyradio label {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #D1D3D4;
  font-weight: normal;
}

.funkyradio input[type="radio"]:empty,
.funkyradio input[type="checkbox"]:empty {
  display: none;
}

.funkyradio input[type="radio"]:empty ~ label,
.funkyradio input[type="checkbox"]:empty ~ label {
  position: relative;
  line-height: 2.5em;
  text-indent: 3.25em;
  /*margin-top: 2em;*/
  margin-bottom: 0em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.funkyradio input[type="radio"]:empty ~ label:before,
.funkyradio input[type="checkbox"]:empty ~ label:before {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  content: '';
  width: 2.5em;
  background: #D1D3D4;
  border-radius: 3px 0 0 3px;
}

.funkyradio input[type="radio"]:hover:not(:checked) ~ label,
.funkyradio input[type="checkbox"]:hover:not(:checked) ~ label {
  color: #888;
}

.funkyradio input[type="radio"]:hover:not(:checked) ~ label:before,
.funkyradio input[type="checkbox"]:hover:not(:checked) ~ label:before {
  content: '\2714';
  text-indent: .9em;
  color: #C2C2C2;
}

.funkyradio input[type="radio"]:checked ~ label,
.funkyradio input[type="checkbox"]:checked ~ label {
  color: #777;
}

.funkyradio input[type="radio"]:checked ~ label:before,
.funkyradio input[type="checkbox"]:checked ~ label:before {
  content: '\2714';
  text-indent: .9em;
  color: #333;
  background-color: #ccc;
}

.funkyradio input[type="radio"]:focus ~ label:before,
.funkyradio input[type="checkbox"]:focus ~ label:before {
  box-shadow: 0 0 0 3px #999;
}

.funkyradio-primary input[type="radio"]:checked ~ label:before,
.funkyradio-primary input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: var(--main-color);
}

/*
  CSS Track List
 */
.badge-active {
  background-color: #2dc937;
}

.badge-inactive{
  background-color: #cc3232;
}

.col-tracks{
  overflow-y: auto;
  height: 65vh;
}

.col-tracks.empty-col{
  height: auto !important;
}

/*
  CSS Webservice
 */
.form-possible-values{
  margin-top: 0.5em;
}
.image-preview-input {
  position: relative;
  overflow: hidden;
  margin: 0px;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.image-preview-input input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
.image-preview-input-title {
  margin-left:2px;
}
.col-ws{
  overflow-y: auto;
  height: 90vh;
}

.popover {
    max-width: 800px; /* optional max width */
    width: intrinsic; /* Safari/WebKit uses a non-standard name */
    width: -moz-max-content; /* Firefox/Gecko */
    width: -webkit-max-content; /* Chrome */
}
